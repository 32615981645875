import { Uuid } from '@bas/value-objects';
import dayjs, { Dayjs } from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { secureZustandStorage } from './secureZustandStorage';

type AuthValues = {
  isAuthenticated?: boolean;
  employeeId?: Uuid;
  userId?: Uuid;
  ducalisHash?: string;
  intercomHash?: string;
  intercomIOSHash?: string;
  intercomAndroidHash?: string;
  mercureToken?: string;
  refresh_token?: string;
  refresh_token_expiration?: number;
  token?: string;
  initialPath?: string;
  bootedIntercom?: boolean;
  expiresAt?: Date;
  sessionId?: Uuid;
  initialized?: boolean;
  isRefreshing?: boolean;
};

type AuthStoreType = AuthValues & {
  logout: () => void;
  setAuth: (values: AuthValues) => void;
  setInitialPath: (path: string) => void;
  getToken: () => string;
  getExpiresAt: () => Dayjs;
  setIsRefreshing: (isRefreshing: boolean) => void;
  getIsRefreshing: () => boolean;
  getRefreshToken: () => string | undefined;
};

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      isRefreshing: false,
      token: undefined,
      mercureToken: undefined,
      refresh_token: undefined,
      refresh_token_expiration: undefined,
      employeeId: undefined,
      userId: undefined,
      ducalisHash: undefined,
      intercomHash: undefined,
      intercomIOSHash: undefined,
      intercomAndroidHash: undefined,
      initialPath: undefined,
      expiresAt: undefined,
      sessionId: undefined,
      initialized: false,
      getRefreshToken: () => get().refresh_token,
      setInitialPath: (path: string) => set({ initialPath: path }),
      getToken: () => get().token || '',
      getExpiresAt: () => dayjs(get().expiresAt || '2023-01-01'),
      setAuth: (values: AuthValues) =>
        set({
          ...values,
          isAuthenticated: !!values.token,
          isRefreshing: false,
        }),
      setIsRefreshing: (isRefreshing) => set({ isRefreshing }),
      getIsRefreshing: () => get().isRefreshing || false,
      logout: () =>
        set({
          isAuthenticated: false,
          token: undefined,
          mercureToken: undefined,
          refresh_token: undefined,
          refresh_token_expiration: undefined,
          employeeId: undefined,
          userId: undefined,
          ducalisHash: undefined,
          intercomHash: undefined,
          intercomIOSHash: undefined,
          intercomAndroidHash: undefined,
          initialPath: undefined,
          expiresAt: undefined,
          sessionId: undefined,
        }),
    }),
    {
      name: 'auth',
      storage: secureZustandStorage<AuthStoreType>(),
      merge: (persistedState, initialState) => {
        if (!persistedState) {
          return { ...initialState, initialized: true };
        }

        return { ...initialState, ...persistedState, initialized: true };
      },
    }
  )
);
