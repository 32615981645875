import { Uuid } from '@bas/value-objects';
import { v7 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { secureZustandStorage } from './secureZustandStorage';

type AppStoreType = {
  initialized: boolean;
  deviceId: Uuid;
  appUrl: string;
  apiUrl: string;
  mercureUrl: string;
  sessionBlocked: boolean;
  textFieldType: 'native' | 'old' | 'paper' | 'rn-ui';
  handleChangeEnvironment: (
    appUrl: string,
    apiUrl: string,
    mercureUrl: string
  ) => void;
  blockSession: () => void;
  unblockSession: () => void;
  requestedPermissionAndroid: boolean;
  setRequestedPermissionAndroid: (requestedPermissionAndroid: boolean) => void;
  setTextFieldType: (
    textFieldType: 'native' | 'old' | 'paper' | 'rn-ui'
  ) => void;
  getApiUrl: () => string;
  hasGpuAcceleration: boolean;
  setHasGpuAcceleration: (hasGpuAcceleration: boolean) => void;
  enableBackgroundLocation: boolean;
  setEnableBackgroundLocation: (enableBackgroundLocation: boolean) => void;
  lastCheckForUpdateTime?: Date;
  setLastCheckForUpdateTime: (lastCheckForUpdateTime: Date) => void;
  availableVersion?: {
    version: string;
    update: string;
  };
  setAvailableVersion: (version: string, update: string) => void;
  resetAvailableVersion: () => void;
};

export const useAppStore = create<AppStoreType>()(
  persist(
    (set, get) => ({
      initialized: false,
      requestedPermissionAndroid: false,
      hasGpuAcceleration: true,
      enableBackgroundLocation: false,
      setEnableBackgroundLocation: (enableBackgroundLocation: boolean) =>
        set({ enableBackgroundLocation }),
      setHasGpuAcceleration: (hasGpuAcceleration: boolean) =>
        set({ hasGpuAcceleration }),
      setRequestedPermissionAndroid: (requestedPermissionAndroid: boolean) =>
        set({ requestedPermissionAndroid }),
      deviceId: v7(),
      getApiUrl: () => get().apiUrl || '',
      appUrl: '',
      apiUrl: '',
      mercureUrl: '',
      sessionBlocked: false,
      textFieldType: 'native',
      handleChangeEnvironment: (
        appUrl: string,
        apiUrl: string,
        mercureUrl: string
      ) => set({ appUrl, apiUrl, mercureUrl }),
      blockSession: () => set({ sessionBlocked: true }),
      unblockSession: () => set({ sessionBlocked: false }),
      setTextFieldType: (textFieldType: 'native' | 'old' | 'paper' | 'rn-ui') =>
        set({ textFieldType }),
      lastCheckForUpdateTime: undefined,
      setLastCheckForUpdateTime: (lastCheckForUpdateTime: Date) =>
        set({ lastCheckForUpdateTime }),
      availableVersion: undefined,
      resetAvailableVersion: () => set({ availableVersion: undefined }),
      setAvailableVersion: (version: string, update: string) =>
        set({ availableVersion: { version, update } }),
    }),
    {
      version: 1,
      merge: (persistedState, initialState) => {
        if (!persistedState) {
          return { ...initialState, initialized: true };
        }

        return { ...initialState, ...persistedState, initialized: true };
      },
      name: 'app-store',
      storage: secureZustandStorage<AppStoreType>(),
    }
  )
);
